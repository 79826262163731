import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { useFilters } from './useFilters'
import OwnerGroup from '../../../lib/OwnerGroup'
import { useCategoryMappings } from '../../../hooks/useCategoryMappings'

interface Props {
  ownerGroups: OwnerGroup[]
}

const DetailedConditions: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const { state, dispatch } = useFilters()
  const categoryMappings = useCategoryMappings()

  const categories = Array.from(
    new Map(
      [
        { value: '', label: `(${t('指定なし')})` },
        { value: 'null', label: t('カテゴリなし') },
        { value: 'E', label: 'E' },
        { value: 'M', label: 'M' },
        { value: 'T', label: 'T' },
        { value: 'P', label: 'P' },
        { value: 'K', label: 'K' },
        ...(categoryMappings?.map(categoryMapping => {
          return { value: categoryMapping.index, label: categoryMapping.name }
        }) ?? [])
      ].map(category => [category.value, category])
    ).values()
  )

  const valueToCategoryName = (value: string): string => {
    const filtered = categories.filter(category => {
      return category.value === value
    })
    return filtered[0]?.label || value
  }

  return (
    <>
      <Row>
        <Col sm={4}>
          <Form.Group as={Row} controlId="confidence">
            <Form.Label column sm={4}>
              {t('信頼度')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      confidence: e.currentTarget.value
                    }
                  })
                }
                pattern="^100$|^[0-9]{1,2}$"
                value={state.confidence}
              ></Form.Control>
              <Form.Text>{t('0〜100')}</Form.Text>
            </Col>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('所属先名')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      ownerGroupId: e.currentTarget.value
                    }
                  })
                }
                value={state.ownerGroupId}
              >
                <option value="">({t('指定なし')})</option>
                {props.ownerGroups.map(ownerGroup => {
                  return (
                    <option key={ownerGroup._id} value={ownerGroup._id}>
                      {ownerGroup.name}
                    </option>
                  )
                })}
              </Form.Control>
            </Col>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('カテゴリ')}:
            </Form.Label>
            <Col sm={8}>
              <Select
                options={categories}
                value={{
                  value: state.category,
                  label: valueToCategoryName(state.category || '')
                }}
                onChange={(selected): void => {
                  dispatch({
                    type: 'UPDATE',
                    payload: { ...state, category: selected?.value || '' }
                  })
                }}
                filterOption={({ label }, inputValue): boolean => {
                  return label.toLowerCase().includes(inputValue.toLowerCase())
                }}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('インポート元TM名')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      tmName: e.currentTarget.value
                    }
                  })
                }
                value={state.tmName}
              />
              <Form.Text>{t('部分一致 (大文字小文字を区別しない)')}</Form.Text>
            </Col>
          </Form.Group>
        </Col>
        <Col sm={5}>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              {t('作成日時')}:
            </Form.Label>
            <Col sm="auto">
              <DatePicker
                className="form-control"
                dateFormat="yyyy-MM-dd HH:mm"
                onChange={(date): void =>
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      createdAtFrom: date
                    }
                  })
                }
                selected={state.createdAtFrom}
                showTimeSelect
              />
            </Col>
            <Col sm="auto">{' 〜 '}</Col>
            <Col sm="auto">
              <DatePicker
                className="form-control"
                dateFormat="yyyy-MM-dd HH:mm"
                onChange={(date): void =>
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      createdAtTo: date
                    }
                  })
                }
                selected={state.createdAtTo}
                showTimeSelect
              />
            </Col>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('有効のみ表示')}:
            </Form.Label>
            <Form.Check
              inline
              checked={state.active}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                dispatch({
                  type: 'UPDATE',
                  payload: { ...state, active: e.currentTarget.checked }
                })
              }}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default DetailedConditions
