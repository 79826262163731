import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { format } from 'date-fns'
import queryString from 'query-string'

import { useFilters } from './useFilters'
import OwnerGroup from '../../../lib/OwnerGroup'
import { langCodeToLangName, lookupValue } from '../../../lib/Utils'
import { useCategoryMappings } from '../../../hooks/useCategoryMappings'

interface Props extends RouteComponentProps {
  ownerGroups: OwnerGroup[]
}

const Footer: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const { state, dispatch } = useFilters()
  const categoryMappings = useCategoryMappings()

  const ownerGroupMappings = props.ownerGroups.reduce((acc: { [key: string]: any }, val) => {
    const result = acc
    result[val._id] = val.name
    return result
  }, {})

  const categoryMappingMappings = categoryMappings?.reduce((acc: { [key: string]: any }, val) => {
    const result = acc
    result[val.index] = val.name
    return result
  }, {})

  const filtersToString = (): string => {
    const qs = props.location.search
    const query = queryString.parse(qs)
    const strings = [
      typeof query['srcLang'] === 'string'
        ? `${t('ソース言語')}: ${langCodeToLangName(query['srcLang'])}`
        : '',
      typeof query['[srcTmx][$regex]'] === 'string'
        ? `${t('原文')}: ${query['[srcTmx][$regex]']}`
        : '',
      typeof query['tgtLang'] === 'string'
        ? `${t('ターゲット言語')}: ${langCodeToLangName(query['tgtLang'])}`
        : '',
      typeof query['[tgtTmx][$regex]'] === 'string'
        ? `${t('訳文')}: ${query['[tgtTmx][$regex]']}`
        : '',
      typeof query['confidence'] === 'string' ? `${t('信頼度')}: ${query['confidence']}` : '',
      typeof query['ownerGroupId'] === 'string'
        ? `${t('所属先名')}: ${
            lookupValue(ownerGroupMappings, query['ownerGroupId']) !== undefined
              ? lookupValue(ownerGroupMappings, query['ownerGroupId'])
              : ''
          }`
        : '',
      typeof query['[properties.category]'] === 'string'
        ? `${t('カテゴリ')}: ${
            query['[properties.category]'] === 'null'
              ? t('カテゴリなし')
              : categoryMappingMappings &&
                lookupValue(categoryMappingMappings, query['[properties.category]']) !== undefined
              ? lookupValue(categoryMappingMappings, query['[properties.category]'])
              : query['[properties.category]']
          }`
        : '',
      typeof query['[properties.tmName][$regex]'] === 'string'
        ? `${t('インポート元TM名')}: ${query['[properties.tmName][$regex]']}`
        : '',
      ((): string => {
        if (query['createdAt[$gte]'] || query['createdAt[$lte]']) {
          return `${t('作成日時')}: ${
            typeof query['createdAt[$gte]'] === 'string'
              ? format(new Date(query['createdAt[$gte]']), 'yyyy-MM-dd HH:mm:SS')
              : ''
          } 〜 ${
            typeof query['createdAt[$lte]'] === 'string'
              ? format(new Date(query['createdAt[$lte]']), 'yyyy-MM-dd HH:mm:SS')
              : ''
          }`
        }
        return ''
      })(),
      typeof query['active'] === 'string' && query['active'] === 'true' ? t('有効のみ表示') : ''
    ]

    return strings.filter(string => string !== '').join(', ')
  }

  const clearFilters = (): void => {
    dispatch({
      type: 'CLEAR',
      payload: state
    })
    localStorage.removeItem('TMListFilters')
    if (props.location.search.length > 0) {
      props.history.push('/tm-entries')
    }
    return
  }

  return (
    <Row>
      <Col sm={12}>
        <Button type="submit">{t('検索')}</Button>
        <Button className="ml-2" onClick={clearFilters} variant="secondary">
          {t('絞り込み解除')}
        </Button>
        <Button disabled variant="link">
          {t('現在の絞り込み条件')}: {filtersToString() === '' ? t('なし') : filtersToString()}
        </Button>
      </Col>
    </Row>
  )
}

export default withRouter(Footer)
