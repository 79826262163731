import React from 'react'
import { useTranslation } from 'react-i18next'
import { AgGridColumnProps } from 'ag-grid-react'

type Props = AgGridColumnProps & {
  displayName: string
}

const CustomHeader: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  return (
    <div className="ag-cell-label-container" role="presentation">
      <div className="ag-header-cell-label" role="presentation">
        <span className="ag-header-cell-text" role="columnHeader">
          {t(props.displayName)}
        </span>
      </div>
    </div>
  )
}

export default CustomHeader
