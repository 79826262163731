import { ColDef } from '@ag-grid-community/core'
import { useTranslation } from 'react-i18next'

import {
  DateTimeFormatter,
  EmptyCellFormatter,
  LanguageNameFormatter,
  IsEnabledFormatter
} from '../../lib/ValueFormatters'
import { lookupValue } from '../../lib/Utils'
import { useOwnerGroups } from '../../hooks/useOwnerGroups'
import { useVendors } from '../../hooks/useVendors'
import { useCategoryMappings } from '../../hooks/useCategoryMappings'

const useColumnDefs = (): { columnDefs: ColDef[]; defaultColDef: ColDef } => {
  const ownerGroups = useOwnerGroups(true)
  const vendors = useVendors(true)
  const categoryMappings = useCategoryMappings()
  const { t } = useTranslation()

  const ownerGroupMappings = ownerGroups?.reduce((acc: { [key: string]: any }, val) => {
    const result = acc
    result[val._id] = val.name
    return result
  }, {})

  const vendorMappings = vendors?.reduce((acc: { [key: string]: any }, val) => {
    const result = acc
    result[val._id] = `${val.name} (${val.srcLang} -> ${val.tgtLang})`
    return result
  }, {})

  const categoryMappingMappings = categoryMappings?.reduce((acc: { [key: string]: any }, val) => {
    const result = acc
    result[val.index] = val.name
    return result
  }, {})

  const defaultColDef: ColDef = {
    lockVisible: true,
    minWidth: 50,
    resizable: true,
    sortable: false,
    width: 200
  }

  const columnDefs: ColDef[] = [
    {
      colId: '_id',
      checkboxSelection: true,
      headerName: '',
      field: '_id',
      maxWidth: 50,
      minWidth: 50,
      resizable: false,
      valueFormatter: EmptyCellFormatter,
      width: 50,
      hide: true
    },
    {
      colId: 'editButton',
      headerName: '',
      width: 50,
      cellRenderer: 'EditTMButtonRenderer'
    },
    {
      colId: 'srcTmx',
      headerName: '原文',
      field: 'srcTmx',
      width: 300,
      editable: false,
      cellEditor: 'TMEntryCellEditor',
      singleClickEdit: false,
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        'white-space': 'normal',
        'overflow-wrap': 'anywhere',
        'text-overflow': 'ellipsis'
      }
    },
    {
      colId: 'tgtTmx',
      headerName: '訳文',
      field: 'tgtTmx',
      width: 300,
      editable: false,
      singleClickEdit: false,
      cellEditor: 'TMEntryCellEditor',
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        'white-space': 'normal',
        'overflow-wrap': 'anywhere'
        // 'max-height': '100px'
      }
    },
    {
      colId: 'srcLang',
      headerName: 'ソース言語',
      field: 'srcLang',
      valueFormatter: LanguageNameFormatter
    },
    {
      colId: 'tgtLang',
      headerName: 'ターゲット言語',
      field: 'tgtLang',
      valueFormatter: LanguageNameFormatter
    },
    {
      colId: 'confidence',
      headerName: '信頼度',
      field: 'confidence',
      width: 100,
      editable: false,
      cellEditor: 'ConfidenceCellEditor',
      singleClickEdit: false
    },
    {
      colId: 'ownerGroupId',
      headerName: '所属先名',
      field: 'ownerGroupId',
      editable: false,
      singleClickEdit: false,
      cellEditor: 'TMEntryCellEditor',
      cellEditorParams: {
        values: ownerGroups
      },
      cellRenderer: 'OwnerGroupCellRenderer',
      valueFormatter: params => {
        return ownerGroupMappings !== undefined
          ? ownerGroupMappings
            ? lookupValue(ownerGroupMappings, params.value)
            : params.value
          : params.value
      }
    },
    {
      colId: 'vendorId',
      headerName: '翻訳会社',
      field: 'vendorId',
      editable: false,
      singleClickEdit: false,
      cellEditor: 'VendorCellEditor',
      cellEditorParams: {
        values: vendors
      },
      cellRenderer: 'VendorCellRenderer',
      valueFormatter: params => {
        return vendorMappings !== undefined
          ? vendorMappings
            ? lookupValue(vendorMappings, params.value)
            : params.value
          : params.value
      }
    },
    {
      colId: 'category',
      headerName: 'カテゴリ',
      field: 'category',
      width: 100,
      editable: false,
      cellEditor: 'CategoryCellEditor',
      singleClickEdit: false,
      valueFormatter: params => {
        return categoryMappingMappings !== undefined
          ? categoryMappingMappings
            ? lookupValue(categoryMappingMappings, params.value)
            : params.value
          : params.value
      },
      cellEditorParams: {
        values: Array.from(
          new Map(
            [
              { value: '', label: t('カテゴリなし') },
              { value: 'E', label: 'E' },
              { value: 'M', label: 'M' },
              { value: 'T', label: 'T' },
              { value: 'P', label: 'P' },
              { value: 'K', label: 'K' },
              ...(categoryMappings?.map(categoryMapping => {
                return { value: categoryMapping.index, label: categoryMapping.name }
              }) ?? [])
            ].map(category => [category.value, category])
          ).values()
        )
      }
    },
    {
      colId: 'tmName',
      headerName: 'インポート元TM名',
      field: 'tmName'
    },
    {
      colId: 'tmType',
      headerName: 'インポート先TM種別',
      field: 'tmType'
    },
    {
      colId: 'active',
      headerName: '有効',
      field: 'active',
      valueFormatter: IsEnabledFormatter,
      width: 100,
      editable: false,
      singleClickEdit: false,
      cellEditor: 'ActiveInactiveCellEditor',
      cellEditorParams: { values: [true, false] }
    },
    {
      colId: 'createdAt',
      headerName: '作成日時',
      field: 'createdAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    {
      colId: 'createdBy',
      headerName: '作成者',
      field: 'createdBy'
    },
    {
      colId: 'updatedAt',
      headerName: '更新日時',
      field: 'updatedAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    {
      colId: 'updatedBy',
      headerName: '更新者',
      field: 'updatedBy'
    },
    {
      colId: 'editable',
      field: 'editable',
      hide: true
    }
  ]

  return { defaultColDef, columnDefs }
}

export default useColumnDefs
