import React from 'react'
import { Badge, Button, ListGroup, Modal, Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useImportTmxDialog, State, Action } from './useImportTmxDialog'
import OwnerGroup from '../../../lib/OwnerGroup'
import Vendor from '../../../lib/Vendor'
import { formatBytes } from '../../../lib/Utils'
import { BackendError } from '../../../lib/BackendError'
import { useLoginUser } from '../../../hooks/useLoginUser'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'

type ContainerProps = {
  close: () => void
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { close: closeDialog, handleSubmit, onKeyPress, state, dispatch } = props
  const { t } = useTranslation()

  const onSelectedFilesChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const targetFiles = e.target.files || []
    const files = Array.from(targetFiles).map(f => f)
    dispatch({ type: 'SET_FORM_VALUE', payload: { selectedFiles: files } })
    dispatch({ type: 'SET_MESSAGE', payload: { message: '' } })
  }

  const langOptions = (
    <>
      <option value="de-DE">{t('German')}</option>
      <option value="en-US">{t('English (US)')}</option>
      <option value="es-MX">{t('Spanish (Mexico)')}</option>
      <option value="fr-FR">{t('French')}</option>
      <option value="it-IT">{t('Italian')}</option>
      <option value="ja-JP">{t('Japanese')}</option>
      <option value="ko-KR">{t('Korean')}</option>
      <option value="zh-CN">{t('Chinese (Simplified)')}</option>
      <option value="zh-TW">{t('Chinese (Taiwan)')}</option>
      <option value="th-TH">{t('Thai')}</option>
    </>
  )

  return (
    <Modal onHide={closeDialog} show={true} size="lg">
      <Form onKeyPress={onKeyPress} onSubmit={handleSubmit}>
        <Modal.Header closeButton>{t('TMXインポート')}</Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} controlId="srcLang">
            <Form.Label column sm={4}>
              {t('ソース言語')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({ type: 'SET_FORM_VALUE', payload: { srcLang: e.currentTarget.value } })
                }
                value={state.formData.srcLang}
                data-testid="select-src-lang"
              >
                {langOptions}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="tgtLang">
            <Form.Label column sm={4}>
              {t('ターゲット言語')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({ type: 'SET_FORM_VALUE', payload: { tgtLang: e.currentTarget.value } })
                }
                value={state.formData.tgtLang}
                data-testid="select-tgt-lang"
              >
                {langOptions}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="confidence">
            <Form.Label column sm={4}>
              {t('信頼度')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { confidence: e.currentTarget.value }
                  })
                }
                pattern="^100$|^[0-9]{1,2}$"
                required
                value={state.formData.confidence}
                data-testid="input-confidence"
              />
              <Form.Text>
                {t('0〜100')} /{' '}
                {t('TMXファイル内で信頼度が指定されている場合、その値が優先されます。')}
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="ownerGroupId">
            <Form.Label column sm={4}>
              {t('所属先')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { ownerGroupId: e.currentTarget.value }
                  })
                }
                value={state.formData.ownerGroupId ? state.formData.ownerGroupId : ''}
                data-testid="select-owner-group-id"
              >
                {state.ownerGroups.map((ownerGroup: OwnerGroup) => {
                  return (
                    <option key={ownerGroup._id} value={ownerGroup._id}>
                      {ownerGroup.name}
                    </option>
                  )
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="vendorId">
            <Form.Label column sm={4}>
              {t('翻訳会社')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({ type: 'SET_FORM_VALUE', payload: { vendorId: e.currentTarget.value } })
                }
                value={state.formData.vendorId ? state.formData.vendorId : ''}
                data-testid="select-vendor-id"
              >
                <option value="">{t('(なし)')}</option>
                {state.vendors
                  .filter((vendor: Vendor) => {
                    return (
                      vendor.srcLang === state.formData.srcLang &&
                      vendor.tgtLang === state.formData.tgtLang
                    )
                  })
                  .map((vendor: Vendor) => {
                    return (
                      <option key={vendor._id} value={vendor._id}>
                        {vendor.name}
                      </option>
                    )
                  })}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="category">
            <Form.Label column sm={4}>
              {t('カテゴリ')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({ type: 'SET_FORM_VALUE', payload: { category: e.currentTarget.value } })
                }
                value={state.formData.category}
                data-testid="select-category"
              >
                <option value="null">{t('カテゴリなし')}</option>
                <option value="E">E</option>
                <option value="M">M</option>
                <option value="T">T</option>
                <option value="P">P</option>
                <option value="K">K</option>
              </Form.Control>
              <Form.Text>
                {t('TMXファイル内でカテゴリが指定されている場合、その値が優先されます。')}
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="tmxFiles">
            <Col sm={12}>
              <Form.File
                id="tmx-files"
                label={t('TMXファイルを選択')}
                accept=".tmx"
                onChange={onSelectedFilesChange}
                multiple
                custom
                required
                data-testid="select-tmx-file"
              ></Form.File>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="selectedFilesCount">
            <Col sm={12}>
              <div>
                {t('選択中のファイル数')}: {state.formData.selectedFiles.length}
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="selectedFiles">
            <Col sm={12}>
              <ListGroup>
                {state.formData.selectedFiles.map((file: File) => (
                  <ListGroup.Item
                    key={file.name}
                    className={'d-flex justify-content-between align-items-center'}
                  >
                    {file.name}{' '}
                    <Badge pill variant="primary">
                      {formatBytes(file.size)}
                    </Badge>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <span style={state.message.isError ? { color: 'red' } : undefined}>
            {state.message.text}
          </span>
          <OverlayTriggerButton
            type="submit"
            isOverlayed={(): boolean => state.loading}
            isLoading={(): boolean => state.loading}
            buttonLabel={t('OK')}
            tooltipLabel={t('アップロード中')}
            tooltipId="tooltip-uploading-in-progress"
            variant="primary"
            data-testid="submit-button"
          />
          <Button
            disabled={state.loading}
            onClick={closeDialog}
            style={state.loading ? { pointerEvents: 'none' } : {}}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const loginUser = useLoginUser()
  const { state, dispatch, handleSubmit: uploadFile } = useImportTmxDialog(loginUser)

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const close = (): void => {
    if (!state.loading) {
      props.close()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    try {
      await uploadFile()
      close()
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      onKeyPress={onKeyPress}
    />
  )
}

Container.displayName = 'ImportTmxDialog'
export default Container
