import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import queryString from 'query-string'

import config from '../../../config'
import OwnerGroup from '../../../lib/OwnerGroup'
import BasicConditions from './BasicConditions'
import DetailedConditions from './DetailedConditions'
import Footer from './Footer'
import { useFilters } from './useFilters'

interface Props extends RouteComponentProps {
  className?: string
}

interface Filters {
  [key: string]: string | number | Date | null | boolean
}

const Filters: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const { state, dispatch } = useFilters()

  const [showDetails, setShowDetails] = useState(false)

  const [ownerGroups, setOwnerGroups] = useState<OwnerGroup[]>([])

  const numberOfDetailedConditions = (): number => {
    const detailedConditionKeys = [
      'confidence',
      'ownerGroupId',
      '[properties.category]',
      '[properties.tmName][$regex]',
      'createdAt[$gte]',
      'createdAt[$lte]',
      'active'
    ]

    const qs = props.location.search
    const query = queryString.parse(qs)

    const queryKeys = Object.keys(query)

    return queryKeys
      .filter(key => {
        return detailedConditionKeys.includes(key)
      })
      .reduce((acc: string[], val) => {
        if (val.match(/createdAt/)) {
          if (acc.includes('createdAt[$gte]') || acc.includes('createdAt[$lte]')) {
            return acc
          }
          return [...acc, val]
        }
        return [...acc, val]
      }, []).length
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const ownerGroupsResponse = await axios.get(
        config[config.STAGE].endpoint + '/api/v1/ownerGroups'
      )
      setOwnerGroups(ownerGroupsResponse.data)
    }
    fetchData()

    const qs = props.location.search
    const query = queryString.parse(qs)

    if (Object.keys(query).length === 0) {
      // TODO: LocalStorage
      if (localStorage.getItem('TMListFilters') !== null) {
        const TMListFiltersString = localStorage.getItem('TMListFilters')
        if (typeof TMListFiltersString === 'string') {
          const TMListFilters = JSON.parse(TMListFiltersString)
          // dispatch({ type: 'UPDATE', payload: TMListFilters })
        }
      }
    } else {
      const payload = {
        srcLang: !query['srcLang']
          ? ''
          : typeof query['srcLang'] === 'string'
          ? query['srcLang']
          : '',
        tgtLang: !query['tgtLang']
          ? ''
          : typeof query['tgtLang'] === 'string'
          ? query['tgtLang']
          : '',
        srcTmx: !query['[srcTmx][$regex]']
          ? ''
          : typeof query['[srcTmx][$regex]'] === 'string'
          ? query['[srcTmx][$regex]']
          : '',
        tgtTmx: !query['[tgtTmx][$regex]']
          ? ''
          : typeof query['[tgtTmx][$regex]'] === 'string'
          ? query['[tgtTmx][$regex]']
          : '',
        confidence: !query['confidence']
          ? ''
          : typeof query['confidence'] === 'string'
          ? query['confidence']
          : '',
        ownerGroupId: !query['ownerGroupId']
          ? ''
          : typeof query['ownerGroupId'] === 'string'
          ? query['ownerGroupId']
          : '',
        category: !query['[properties.category]']
          ? ''
          : typeof query['[properties.category]'] === 'string'
          ? query['[properties.category]']
          : '',
        tmName: !query['[properties.tmName][$regex]']
          ? ''
          : typeof query['[properties.tmName][$regex]'] === 'string'
          ? query['[properties.tmName][$regex]']
          : '',
        createdAtFrom: !query['createdAt[$gte]']
          ? null
          : typeof query['createdAt[$gte]'] === 'string'
          ? new Date(query['createdAt[$gte]'])
          : null,
        createdAtTo: !query['createdAt[$lte]']
          ? null
          : typeof query['createdAt[$lte]'] === 'string'
          ? new Date(query['createdAt[$lte]'])
          : null,
        active: !query['active']
          ? false
          : typeof query['active'] === 'string' && query['active'] === 'true'
          ? true
          : false
      }

      dispatch({
        type: 'UPDATE',
        payload: payload
      })
    }
  }, [props])

  const setFilters = (): void => {
    const query: { [key: string]: string | string[] | null | undefined } = {
      srcLang: state.srcLang,
      '[srcTmx][$regex]': state.srcTmx,
      '[srcTmx][$options]': state.srcTmx.length > 0 ? 'i' : '',
      tgtLang: state.tgtLang,
      '[tgtTmx][$regex]': state.tgtTmx,
      '[tgtTmx][$options]': state.tgtTmx.length > 0 ? 'i' : '',
      confidence: state.confidence?.toString(),
      ownerGroupId: state.ownerGroupId,
      '[properties.category]': state.category,
      '[properties.tmName][$regex]': state.tmName,
      'createdAt[$gte]': state.createdAtFrom?.toISOString(),
      'createdAt[$lte]': state.createdAtTo?.toISOString(),
      active: state.active === true ? 'true' : undefined
    }

    Object.keys(query).forEach(key => {
      if (query[key] === '') {
        delete query[key]
      }
      return
    })

    const oldQuery = queryString.parse(props.location.search)
    if (Object.keys(oldQuery).length > 0) {
      Object.keys(oldQuery).forEach((key: string) => {
        if (key.startsWith('options[sort]')) {
          query[key] = oldQuery[key]
        }
      })
    }
    const qs = queryString.stringify(query)

    localStorage.setItem(
      'TMListFilters',
      JSON.stringify({
        srcLang: state.srcLang,
        tgtLang: state.tgtLang,
        srcTmx: state.srcTmx,
        tgtTmx: state.tgtTmx,
        confidence: state.confidence,
        ownerGroupId: state.ownerGroupId,
        category: state.category,
        tmName: state.tmName,
        createdAtFrom: state.createdAtFrom,
        createdAtTo: state.createdAtTo,
        active: state.active
      })
    )

    props.history.push(`/tm-entries${qs.length > 0 ? '?' : ''}${qs}`)
    return
  }

  return (
    <Card className="ml-3 mr-3 mb-3">
      <div className="mt-3 ml-3 mr-3 mb-2">
        <Form className="mt-3 ml-3 mr-3" onSubmit={setFilters}>
          <BasicConditions />
          <Row>
            <Button
              className="mb-2"
              onClick={(): void => setShowDetails(!showDetails)}
              variant="link"
            >
              {showDetails ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronRight} />
              )}{' '}
              {t('詳細条件')} ({numberOfDetailedConditions()})
            </Button>
          </Row>
          {showDetails === true && <DetailedConditions ownerGroups={ownerGroups} />}
          <Footer ownerGroups={ownerGroups} />
        </Form>
      </div>
    </Card>
  )
}

export default withRouter(Filters)
