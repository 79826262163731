import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Project from '../../lib/Project'
import { ProjectDetailPageContext } from './useProjectDetailPage'
import CreateTranslationKitDialog from '../../components/dialogs/CreateTranslationKitDialog'
import DownloadTranslatedFilesDialog from '../../components/dialogs/DownloadTranslatedFilesDialog'
import JobFiltersDialog from '../../components/dialogs/JobFiltersDialog'
import RemoveJobsDialog from '../../components/dialogs/RemoveJobsDialog'
import SelectJobsDialog from '../../components/dialogs/SelectJobsDialog'
import SendToMdmDialog from '../../components/dialogs/SendToMdmDialog'
import UploadTranslatedXliffsDialog from '../../components/dialogs/UploadTranslatedXliffsDialog'

type ContainerProps = {
  project: Project
  refreshJobList: () => void
}

type Props = ContainerProps & {
  closeSelectJobsDialog: (updated: boolean) => void
  closeRemoveJobsDialog: (updated: boolean) => void
  closeCreateTranslationKitDialog: (creating: boolean) => void
  closeUploadTranslatedXliffDialog: () => void
  closeDownloadTranslatedFilesDialog: () => void
  closeSendToMdmDialog: (updated: boolean) => void
  closeJobFiltersDialog: () => void
}

const Component: React.FC<Props> = (props: Props) => {
  const {
    project,
    closeSelectJobsDialog,
    closeRemoveJobsDialog,
    closeCreateTranslationKitDialog,
    closeUploadTranslatedXliffDialog,
    closeDownloadTranslatedFilesDialog,
    closeSendToMdmDialog,
    closeJobFiltersDialog
  } = props
  const { t } = useTranslation()
  const { state } = useContext(ProjectDetailPageContext)

  return (
    <>
      {state.showSelectJobsDialog && (
        <SelectJobsDialog
          close={closeSelectJobsDialog}
          projectId={props.project._id}
          srcLang={props.project.srcLang}
          tgtLang={props.project.tgtLang}
          title={t('ジョブを追加')}
        />
      )}
      {state.showRemoveJobsDialog && (
        <RemoveJobsDialog close={closeRemoveJobsDialog} jobs={state.selectedJobs} />
      )}
      {state.showCreateTranslationKitDialog && (
        <CreateTranslationKitDialog close={closeCreateTranslationKitDialog} project={project} />
      )}
      {state.showUploadTranslatedXliffDialog && (
        <UploadTranslatedXliffsDialog
          close={closeUploadTranslatedXliffDialog}
          projectId={project._id}
          title={t('翻訳済みXLIFFをアップロード')}
        />
      )}
      {state.showDownloadTranslatedFilesDialog && (
        <DownloadTranslatedFilesDialog
          close={closeDownloadTranslatedFilesDialog}
          project={project}
          title={t('翻訳済みジョブをダウンロード')}
        />
      )}

      {state.showSendToMdmDialog && (
        <SendToMdmDialog close={closeSendToMdmDialog} project={project} title={t('完了')} />
      )}
      {state.showJobFiltersDialog && (
        <JobFiltersDialog close={closeJobFiltersDialog} project={project} />
      )}
    </>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { refreshJobList } = props
  const { dispatch } = useContext(ProjectDetailPageContext)

  const closeSelectJobsDialog = (updated: boolean): void => {
    dispatch({
      type: 'SET_SHOW_SELECT_JOBS_DIALOG',
      payload: { show: false }
    })
    if (updated) {
      refreshJobList()
    }
  }

  const closeRemoveJobsDialog = (updated: boolean): void => {
    dispatch({
      type: 'SET_SHOW_REMOVE_JOBS_DIALOG',
      payload: { show: false }
    })
    if (updated) {
      refreshJobList()
    }
  }

  const closeCreateTranslationKitDialog = (creating: boolean): void => {
    // creating === true: 翻訳キットを生成中はボタン押下不可
    dispatch({
      type: 'SET_CAN_CREATE_TRANSLATION_KIT',
      payload: { canCreateTranslationKit: !creating }
    })
    dispatch({
      type: 'SET_SHOW_CREATE_TRANSLATION_KIT_DIALOG',
      payload: { show: false }
    })
  }

  const closeUploadTranslatedXliffDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_UPLOAD_TRANSLATED_XLIFF_DIALOG',
      payload: { show: false }
    })
  }

  const closeDownloadTranslatedFilesDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_DOWNLOAD_TRANSLATED_FILES_DIALOG',
      payload: { show: false }
    })
  }

  const closeSendToMdmDialog = (updated: boolean): void => {
    dispatch({
      type: 'SET_SHOW_SEND_TO_MDM_DIALOG',
      payload: { show: false }
    })
    if (updated) {
      refreshJobList()
    }
  }

  const closeJobFiltersDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_JOB_FILTERS_DIALOG',
      payload: { show: false }
    })
  }

  const childProps: Props = {
    ...props,
    closeSelectJobsDialog,
    closeRemoveJobsDialog,
    closeCreateTranslationKitDialog,
    closeUploadTranslatedXliffDialog,
    closeDownloadTranslatedFilesDialog,
    closeSendToMdmDialog,
    closeJobFiltersDialog
  }

  return <Component {...childProps} />
}

Container.displayName = 'Dialogs'
export default Container
