import React from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Job from '../../../lib/Job'
import { BackendError } from '../../../lib/BackendError'
import JobList from '../../JobList'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import useSelectJobsDialog, { State, Action } from './useSelectJobsDialog'

type ContainerProps = {
  close: (updated: boolean) => void
  projectId: string
  srcLang?: string
  tgtLang?: string
  title: string
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
  applyFilters: () => void
  clearFilters: () => void
  updateSelection: (selected: Job[]) => void
  selectedJobsIncludeInitializingJobs: () => boolean
}

export const Component: React.FC<Props> = (props: Props) => {
  const {
    state,
    dispatch,
    onKeyPress,
    handleSubmit,
    applyFilters,
    clearFilters,
    updateSelection,
    selectedJobsIncludeInitializingJobs
  } = props
  const { t } = useTranslation()

  let button
  if (selectedJobsIncludeInitializingJobs()) {
    button = (
      <OverlayTriggerButton
        isOverlayed={(): boolean => true}
        buttonLabel="OK"
        tooltipLabel={t('準備中のジョブが選択されています')}
        tooltipId="tooltip-initializing-jobs-selected"
        variant="primary"
        data-testid="submit-button"
      />
    )
  } else if (state.selectedJobs.length === 0) {
    button = (
      <OverlayTriggerButton
        isOverlayed={(): boolean => true}
        buttonLabel="OK"
        tooltipLabel={t('ジョブが選択されていません')}
        tooltipId="tooltip-no-jobs-selected"
        variant="primary"
        data-testid="submit-button"
      />
    )
  } else {
    button = (
      <OverlayTriggerButton
        isOverlayed={(): boolean => state.loading}
        isLoading={(): boolean => state.loading}
        buttonLabel="OK"
        tooltipLabel={t('処理中')}
        tooltipId="tooltip-adding-jobs-in-progress"
        variant="primary"
        type="submit"
        data-testid="submit-button"
      />
    )
  }

  return (
    <Modal
      onHide={(): void => props.close(false)}
      show={true}
      size="lg"
      data-testid="select-jobs-dialog"
    >
      <Form onSubmit={handleSubmit} onKeyPress={onKeyPress}>
        <Modal.Header closeButton>{props.title}</Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={8}>
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  {t('案件IDで絞り込み')}:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      dispatch({
                        type: 'SET_SUBJECT_ID',
                        payload: { subjectId: e.currentTarget.value }
                      })
                    }
                    value={state.subjectId}
                    data-testid="input-subject-id"
                  />
                  <Form.Text>{t('前方一致')}</Form.Text>
                </Col>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Button onClick={applyFilters} variant="light" data-testid="apply-filter-button">
                {t('適用')}
              </Button>
              <Button className="ml-2" onClick={clearFilters} variant="light">
                {t('絞り込み解除')}
              </Button>
            </Col>
          </Row>
          <div style={{ height: '65vh' }}>
            <JobList
              options={state.options}
              showDownloadButtonColumn={false}
              showProjectInfoColumns={false}
              updateSelection={updateSelection}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <span style={state.message.isError ? { color: 'red' } : undefined}>
            {state.message.text}
          </span>
          {button}
          <Button
            disabled={state.loading}
            onClick={(): void => props.close(false)}
            style={state.loading ? { pointerEvents: 'none' } : {}}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { projectId, srcLang, tgtLang } = props
  const {
    state,
    dispatch,
    handleSubmit: addJobs,
    applyFilters,
    clearFilters,
    updateSelection,
    selectedJobsIncludeInitializingJobs
  } = useSelectJobsDialog(projectId, srcLang, tgtLang)

  const close = (updated: boolean): void => {
    if (!state.loading) {
      props.close(updated)
    }
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    try {
      await addJobs()
      close(true)
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      onKeyPress={onKeyPress}
      applyFilters={applyFilters}
      clearFilters={clearFilters}
      updateSelection={updateSelection}
      selectedJobsIncludeInitializingJobs={selectedJobsIncludeInitializingJobs}
    />
  )
}

Container.displayName = 'SelectJobsDialog'
export default Container
