import React from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import { BackendError } from '../../../lib/BackendError'
import { useLoginUser } from '../../../hooks/useLoginUser'
import useEditTMDialog, { State, Action } from './useEditTMDialog'
import TM from '../../../lib/TM'

type ContainerProps = {
  close: (updated: boolean) => void
  tm?: TM
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
  onConfirm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { handleSubmit, onKeyPress, state, dispatch, onConfirm } = props
  const { t } = useTranslation()
  const loginUser = useLoginUser()

  const categories = Array.from(
    new Map(
      [
        { value: 'null', label: t('カテゴリなし') },
        { value: 'E', label: 'E' },
        { value: 'M', label: 'M' },
        { value: 'T', label: 'T' },
        { value: 'P', label: 'P' },
        { value: 'K', label: 'K' },
        ...state.categoryMappings.map(categoryMapping => {
          return { value: categoryMapping.index, label: categoryMapping.name }
        })
      ].map(category => [category.value, category])
    ).values()
  )

  const valueToCategoryName = (value: string): string => {
    const filtered = categories.filter(category => {
      return category.value === value
    })
    return filtered[0]?.label || value
  }

  const langOptions = (
    <>
      <option value="de-DE">{t('German')}</option>
      <option value="en-US">{t('English (US)')}</option>
      <option value="es-MX">{t('Spanish (Mexico)')}</option>
      <option value="fr-FR">{t('French')}</option>
      <option value="it-IT">{t('Italian')}</option>
      <option value="ja-JP">{t('Japanese')}</option>
      <option value="ko-KR">{t('Korean')}</option>
      <option value="zh-CN">{t('Chinese (Simplified)')}</option>
      <option value="zh-TW">{t('Chinese (Taiwan)')}</option>
      <option value="th-TH">{t('Thai')}</option>
    </>
  )

  return (
    <>
      <Modal
        onHide={(): void => props.close(false)}
        show={true}
        size="lg"
        data-testid="edit-tm-dialog"
      >
        <Form onKeyPress={onKeyPress} onSubmit={handleSubmit}>
          <Modal.Header closeButton>{t('TMエントリ')}</Modal.Header>
          <Modal.Body>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                {t('原文')}:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="textarea"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    dispatch({ type: 'SET_FORM_VALUE', payload: { srcTmx: e.currentTarget.value } })
                  }
                  value={state.formData.srcTmx}
                  required
                  data-testid="input-src-tmx"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                {t('訳文')}:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="textarea"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    dispatch({ type: 'SET_FORM_VALUE', payload: { tgtTmx: e.currentTarget.value } })
                  }
                  value={state.formData.tgtTmx}
                  data-testid="input-tgt-tmx"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                {t('ソース言語')}:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  disabled={props.tm ? true : false}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                    dispatch({
                      type: 'SET_FORM_VALUE',
                      payload: { srcLang: e.currentTarget.value }
                    })
                  }
                  value={state.formData.srcLang}
                  data-testid="select-src-lang"
                >
                  {langOptions}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                {t('ターゲット言語')}:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  disabled={props.tm ? true : false}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                    dispatch({
                      type: 'SET_FORM_VALUE',
                      payload: { tgtLang: e.currentTarget.value }
                    })
                  }
                  value={state.formData.tgtLang}
                  data-testid="select-tgt-lang"
                >
                  {langOptions}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                {t('信頼度')}:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    dispatch({
                      type: 'SET_FORM_VALUE',
                      payload: { confidence: e.currentTarget.value }
                    })
                  }
                  pattern="^100$|^[0-9]{1,2}$"
                  required
                  value={state.formData.confidence}
                  data-testid="input-confidence"
                />
                <Form.Text>{t('0〜100')}</Form.Text>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                {t('所属先')}:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                    dispatch({
                      type: 'SET_FORM_VALUE',
                      payload: { ownerGroupId: e.currentTarget.value, vendorId: '' }
                    })
                  }}
                  value={state.formData.ownerGroupId ? state.formData.ownerGroupId : ''}
                  data-testid="select-owner-group-id"
                >
                  {state.ownerGroups
                    .filter(ownerGroup => {
                      return loginUser?.organizationType === 'cistate'
                        ? true
                        : loginUser?.organizationIds.includes(ownerGroup._id)
                    })
                    .map(ownerGroup => {
                      return (
                        <option key={ownerGroup._id} value={ownerGroup._id}>
                          {ownerGroup.name}
                        </option>
                      )
                    })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                {t('翻訳会社')}:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                    dispatch({
                      type: 'SET_FORM_VALUE',
                      payload: { vendorId: e.currentTarget.value }
                    })
                  }}
                  value={state.formData.vendorId ? state.formData.vendorId : ''}
                  data-testid="select-vendor-id"
                >
                  <option value="">{t('(なし)')}</option>
                  {state.vendors
                    .filter(vendor => {
                      return (
                        vendor.srcLang === state.formData.srcLang &&
                        vendor.tgtLang === state.formData.tgtLang
                      )
                    })
                    .filter(vendor => {
                      if (state.formData.ownerGroupId !== '') {
                        return vendor.ownerGroupId === state.formData.ownerGroupId
                      }
                      return true
                    })
                    .map(vendor => {
                      return (
                        <option key={vendor._id} value={vendor._id}>
                          {`${vendor.name} (${vendor.srcLang} -> ${vendor.tgtLang})`}
                        </option>
                      )
                    })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                {t('カテゴリ')}:
              </Form.Label>
              <Col sm={9}>
                <Select
                  options={categories}
                  value={{
                    value: state.formData.properties.category,
                    label: valueToCategoryName(state.formData.properties.category || '')
                  }}
                  onChange={(selected): void => {
                    dispatch({
                      type: 'SET_FORM_VALUE',
                      payload: { properties: { category: selected?.value } }
                    })
                  }}
                  filterOption={({ label }, inputValue): boolean => {
                    return label.toLowerCase().includes(inputValue.toLowerCase())
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                {t('有効')}:
              </Form.Label>
              <Col sm={9}>
                <Form.Check
                  checked={state.formData.active === true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    dispatch({
                      type: 'SET_FORM_VALUE',
                      payload: { active: e.currentTarget.checked }
                    })
                  }}
                  data-testid="check-active"
                />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <span style={state.message.isError ? { color: 'red' } : undefined}>
              {state.message.text}
            </span>
            <OverlayTriggerButton
              tooltipLabel={t('処理中')}
              buttonLabel={t('OK')}
              isOverlayed={(): boolean => state.loading}
              isLoading={(): boolean => state.loading}
              tooltipId="tooltip-edit-vendor-in-progress"
              variant="primary"
              type="submit"
              data-testid="submit-button"
            />
            <Button
              disabled={state.loading}
              onClick={(): void => props.close(false)}
              style={state.loading ? { pointerEvents: 'none' } : {}}
              variant="secondary"
            >
              {t('キャンセル')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={state.showConfirmationDialog} style={{ top: '50px' }}>
        <Modal.Body>{t('訳文を空文字列で登録します。よろしいですか？')}</Modal.Body>
        <Modal.Footer>
          <Button onClick={onConfirm} variant="primary">
            {t('OK')}
          </Button>
          <Button
            onClick={(): void =>
              dispatch({ type: 'SET_SHOW_CONFIRMATION_DIALOG', payload: { show: false } })
            }
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { tm } = props

  const { state, dispatch, handleSubmit: editTMEntry } = useEditTMDialog(tm)

  const close = (updated: boolean): void => {
    if (!state.loading) {
      props.close(updated)
    }
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    if (state.formData.tgtTmx.length === 0) {
      dispatch({ type: 'SET_SHOW_CONFIRMATION_DIALOG', payload: { show: true } })
    } else {
      try {
        const result = await editTMEntry()

        if (result) {
          close(true)
        }
      } catch (error) {
        if (error.response) {
          const errorInfo: BackendError = error.response.data
          dispatch({
            type: 'SET_MESSAGE',
            payload: { message: errorInfo.message, isError: true }
          })
        } else {
          dispatch({
            type: 'SET_MESSAGE',
            payload: { message: error.message, isError: true }
          })
        }
      } finally {
        dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
      }
    }
  }

  const onConfirm = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    e.preventDefault()
    dispatch({ type: 'SET_SHOW_CONFIRMATION_DIALOG', payload: { show: false } })
    try {
      await editTMEntry()
      close(true)
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      onKeyPress={onKeyPress}
      handleSubmit={handleSubmit}
      onConfirm={onConfirm}
    />
  )
}

Container.displayName = 'EditTMDialog'
export default Container
